.sidebar {
  //background: violet;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  border-right: 1.5px solid rgb(206, 194, 194);
  min-height: 100vh;

  /*  */
  .top {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 13px;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: rgb(112, 114, 193);
    }
    .imagelogodiv {
      margin-bottom: 2px;
      height: 30px;
      object-fit: contain;
      //justify-content: center;
      //align-items: center;
      .imagelogo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  hr {
    margin: 0;
    height: 0px;
    border: 2px solid rgba(70, 70, 70, 0.387);
    margin-bottom: 4px;
  }

  /*  */
  .center {
    margin-top: 25px;
    padding-left: 10px;
    padding-right: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        //font-size: 15px;
        font-weight: bold;
        color: #999;
      }
      a {
        text-decoration: none;
      }

      li {
        display: flex;
        align-items: center;
        padding: 3px;
        margin: 2px;
        cursor: pointer;

        &:hover {
          border-radius: 10px;
          background-color: #ece8ff;
        }
        span {
          font-size: 15px;
          font-weight: 600;
          color: brown;
        }
      }
    }
  }

  /*  */
  .content-2 {
    margin-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

    .title {
      font-size: 15px;
      font-weight: bold;
      color: #999;
    }
    /* Link is a */
    a {
      text-decoration: none;
    }

    li {
      display: flex;
      align-items: center;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background-color: #ece8ff;
      }
      span {
        font-size: 15px;
        font-weight: 600;
        color: #333;
      }
    }
  }
}

.collapse-btn:hover {
  //background-color: red;
  cursor: pointer;
  //transform: scale(1);
}
