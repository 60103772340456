.dept{
    width:100%;
    .deptContainer{
        flex:7;

        .dataContainer{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
            box-shadow: 2px 4px 10px 1px rgb(201,201,201,0.47);
            padding:20px;
            margin:20px;

            .dataTitle{
                font-size: 30px;
                font-weight:700;
                color:rgb(35, 27, 108);
                
            }
        }
        
    }

    
}