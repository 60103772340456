.mainDrop {
  width: 100%;

  .content {
    width: 100%;
    //background-color: yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
