.category {
  margin: 2px;
  padding: 4px;
  padding-left: 10px;

  /* border-left-style: solid;
  border-right-style: solid;
  border-width: 0.07rem;
  border-color: brown; */
}

.category:hover {
  cursor: pointer;

  border-radius: 10px;
  border-style: none;

  background-color: burlywood;
  transition: background-color 0.3s ease-in-out;
  color: white;
  font-weight: bold;
}

.arrow-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeCategory {
  cursor: pointer;

  margin-top: 10px;
  padding: 5px;
  padding-left: 28px;

  border-radius: 10px;
  background-color: turquoise;
  font-size: larger;
  font-weight: bold;
  color: white;
}
